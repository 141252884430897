<template>
  <div class="column fit">
    <MapboxMap @load="onMapLoaded">
      <MapboxClusters :markers="markersCollection" :colors="['#C0A7EB', '#A78ED2', '#8D74B8']">
        <template slot-scope="marker">
          <h3>{{marker.name}}</h3>
        </template>
      </MapboxClusters>

      <MapboxControls />

      <MapboxScale />
    </MapboxMap>
  </div>
</template>

<style>
</style>

<script>
import { Loading } from 'quasar'
import { MapboxMap, MapboxClusters, MapboxControls, MapboxScale } from '../../components/mapbox'
import { mapGetters } from 'vuex'

export default {
  name: 'EntitiesMap',
  components: { MapboxMap, MapboxClusters, MapboxControls, MapboxScale },

  data () {
    return {
      mapLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      mapEntities: 'entities/getMapEntities',
      filters: 'entities/getFilters'
    }),

    markersCollection () {
      let features = []
      this.mapEntities.forEach(entity => {
        entity.wallets.filter(wallet => wallet.LNG && wallet.LAT).forEach(wallet => {
          let featureSingleWallet = {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [Number(wallet.LNG), Number(wallet.LAT)]
            },
            'properties': {
              'id': entity.id,
              'label': entity.label,
              'sousLabel': wallet.NOMPTF,
              'to': { name: 'entity-show', params: { id: entity.id } },
              'marker-symbol': 'monument'
            }
          }

          features.push(featureSingleWallet)
        })
      })

      return {
        'type': 'FeatureCollection',
        'features': features
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        if (this.mapLoaded) this.fetchMapEntities()
      },
      deep: true
    }
  },

  mounted () {
    Loading.show()
  },

  methods: {
    onMapLoaded () {
      this.mapLoaded = true
      Loading.hide()

      this.fetchMapEntities()
    },

    fetchMapEntities () {
      let params = {}
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      Loading.show()
      this.$store.dispatch('entities/fetchMapEntities', params)
        .finally(() => { Loading.hide() })
    }
  }
}
</script>
